import { Box, Button, Card, CardContent, CardHeader, Grid, SvgIcon, Typography } from "@material-ui/core"
import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import PageTemplate2 from "../templates/page2"
import ContactInfo from "../components/contactInfo"
import Anchor from "../utils/anchor"
import PhoneIcon from "@material-ui/icons/Phone"
import EmailIcon from "@material-ui/icons/Email"

const AboutPage = ({location}) => {
  const {
    file: {
      childMarkdownRemark: {
        frontmatter: { title, coverImage, imageCrop, contacts },
        html,
      },
    },
    site: {
      siteMetadata: {
        contactInfo: {
          address
        }
      }
    }
  } = useStaticQuery(
    graphql`
      {
        file(name: { eq: "about" }) {
          childMarkdownRemark {
            frontmatter {
              title
              coverImage {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                  )
                }
              }
              imageCrop
              contacts {
                name
                phone
                emails
              }
            }
            html
          }
        }
        site {
          siteMetadata {
            contactInfo {
              address {
                line1
                line2
                line3
                city
                country
              }
            }
          }
        }
      }
    `
  )

  const ContactButton = ({ label, url, icon }) => (
    <Button
      variant="text"
      href={url}
      startIcon={<SvgIcon component={icon} fontSize="small" />}
      style={{
        textTransform: "none",
        fontWeight: 500,
        fontSize: "0.875rem",
        fontFamily: "Libre Franklin",
        padding: 0,
      }}
    >
      {label}
    </Button>
  )
  
  return (
    <PageTemplate2
      title={title}
      coverImage={coverImage}
      location={location}
      imageCrop={imageCrop}
    >
      <div dangerouslySetInnerHTML={{ __html: html }} />
      <Grid container spacing={1}>
        {contacts.map(({ name, phone, emails }) => (
          <Grid item xs={12} md={4} key={name}>
            <Card variant="outlined">
              <CardHeader
                title={name}
                disableTypography
                style={{ fontSize: "1rem", fontWeight: 600, paddingBottom: 0 }}
              />
              <CardContent style={{ paddingTop: 8, paddingBottom: 16 }}>
                <ContactButton
                  label={phone}
                  url={`tel:${phone}`}
                  icon={PhoneIcon}
                />
                {emails.map((email, index) => (
                  <ContactButton
                    label={email}
                    url={`mailto:${email}`}
                    icon={EmailIcon}
                    key={index}
                  />
                ))}
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Box my={4}>
        <Typography variant="h3">Address:</Typography>
        <Typography>
          {address.line1}
          <br />
          {address.line2}
          <br />
          {address.line3}
          <br />
          {address.city}, {address.country}
        </Typography>
      </Box>
      <Typography variant="h3">Getting to and from SARRC:</Typography>
      <Typography>
        The archive is easily accessible by car. However, public transport
        options are limited. If you are unable to arrange your own transport,
        please contact the SARRC team, and they may be able to assist your
        travel to and from the archive.
      </Typography>
      <section>
        <Anchor name="contact" />
        <Grid container>
          <Grid item xs={12} md={7}>
            <iframe
              title="Address map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d212474.32683825135!2d72.97617491766549!3d33.68535938997563!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMzPCsDQ3JzMyLjIiTiA3M8KwMTQnMzcuNyJF!5e0!3m2!1sen!2s!4v1634912858804!5m2!1sen!2s"
              width="100%"
              height="400"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
            ></iframe>
          </Grid>
          <Grid item xs={12} md={5}>
            <ContactInfo />
          </Grid>
        </Grid>
      </section>
    </PageTemplate2>
  )
}

export default AboutPage
